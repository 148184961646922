
import './App.css';
import React,{useEffect, useState} from 'react';
import Header from './components/Header';
import HomePageHero from './components/HomePageHero';
import UserListComponent from './components/UserListComponent';
import Box from '@mui/material/Box';
import LandingPage from './pages/LandingPage1';
import LabTabs from './components/Tabs';
import CreateFormComponent from './components/CreateFormComponent';
import MyForm from './components/form';
import SharePage from './pages/SharePage';
import { Route, Routes,Navigate,Redirect  } from "react-router-dom";
function App() {
  const [isPublic,serIsPublic] = useState(true);
  const [screenHeight,setScreenHeight] = useState(0);
  const [screenWidth,setScreenWidth] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);

    
  },[])
  return (
    <div className="Main">
      
      <Routes>
      <Route path="/" element={<iframe className="landingpageiframe" src="https://landing-page-wine-pi.vercel.app/" width={screenWidth} height={screenHeight} ></iframe>}/>
      <Route path="/:username" element={<SharePage/>}/>
      </Routes>
      
    </div>
  );
}

export default App;


{/* <Header/>
<UserListComponent/>
<div>
  <h2 className="PageHeading">Dhashboard</h2>
  <div className="asd">

  <div><LabTabs/></div>
    <div><CreateFormComponent/></div> 
  <div className="formgeneral"><MyForm/></div>
  </div>

  
</div> */}