import React,{useState, useEffect,useRef} from 'react';
import HomePageHero from '../components/HomePageHero';
import Button from '@mui/material/Button';
import Phone from '../static/images/PhoneScreen.jpg';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SharePageListComponent from '../components/Sharepagelistcomponent';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import cake from '../static/images/piececake.png';
import LinkList from './LinkListPage';
import { useLocation,Link, useNavigate, useParams } from "react-router-dom";


const SharePage = () => {
  const { username } = useParams();
  const searchFieldRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [value, setValue] = React.useState('link');
  const [isSearch, setIsSearch] = useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [linkData, setlink] = useState(8);
  const [searchValue, setSearchValue] = React.useState('')
  const [isLinkList, setIsLinkList] = useState(false);
  const [currentLinkList,setCurrentLinkList] = useState('s')
  
  const backend_url = 'https://superrnode.onrender.com';

  const [userImage, setUserImage] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userDescription, setUserDescription] = useState(null);
  const [userIntrest,setUserIntrest] = useState(null);

  const [userProfileData, setUserProfileData] = useState(null);
  const [userLinkData, setUserLinkData] = useState(null);
  const [userLinkListData, setUserLinkListData] = useState(null);
  const [currentLinkListData, setCurrentLinkListData] = useState(null);
  const [isCurrentLinkListData, setIsCurrentLinkListData] = useState(false);

  const user = username;
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${backend_url}/profile/get/${user}`, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const content = await response.json();
        setUserImage(content.user_image);
        setUserName(content.userfullname);
        setUserDescription(content.bio);
        setUserIntrest(content.intrests);
        console.log(content," here is the fetch detailaaaaaaaa");
        console.log(`${backend_url}/profile/get/${user}`);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }


      try {
        const response = await fetch(`${backend_url}/link/get/user/${user}`, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const content = await response.json();
        setUserLinkData(content);
        console.log(content," here is Link data of user");
        console.log(`${backend_url}/profile/get/${user}`);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }

      try {
        const response = await fetch(`${backend_url}/link/linklist/${user}`, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const content = await response.json();
        setUserLinkListData(content);
        console.log(content," here is Link data of user");
        console.log(`${backend_url}/profile/get/${user}`);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }

    })();
  }, []);



  useEffect(() => {
    if(isSearch===true){
      searchFieldRef.current.focus();
    
    }
  }, [isSearch]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let searchResultData = [];


  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(()=>{
    if(isSearchResult===true){
      setIsSearchResult(false);
    }
  },[value])

  useEffect(()=>{
    if(isLinkList===true){
      setIsLinkList(false);
    }
  },[value,isSearchResult])
  // <Stack spacing={2} direction="row">
  //     <CircularProgress variant="determinate" value={progress} />
  //   </Stack>

  function handleSearchIconClick(){
    if(isSearch===false){
      setIsSearch(true);
    }
  
    
    // const searchField = document.getElementById('searchField');
    // searchField.focus();
  }

  function handleSearch()
  {
    // alert("Search Value: "+searchValue);
    if (value === 'linklist') {
      for (let i = 0; i < userLinkListData.length; i++) {
        if (userLinkListData[i].name.toLowerCase().includes(searchValue.toLowerCase()) || userLinkListData[i].description.toLowerCase().includes(searchValue.toLowerCase()) ) {
          searchResultData.push(userLinkListData[i]);
        }
      }
        setSearchResult(searchResultData);
        setIsSearchResult(true);
    } else {
      for (let i = 0; i < userLinkData.length; i++) {
        if (userLinkData[i].title.toLowerCase().includes(searchValue.toLowerCase()) || userLinkData[i].description.toLowerCase().includes(searchValue.toLowerCase())  ) {
          searchResultData.push(userLinkData[i]);
        }
        setSearchResult(searchResultData);
        setIsSearchResult(true);
      }
    }
    console.log(searchResultData,"hereeeeeeeeeeeeeeeeeee");
  }

  function linkclicked(){
    // alert("linkclicked")
  }

  function linklistclicked(linklistid){
    // alert("linklistclicked")
    
   // alert(`${linklistid}`)
    
    (async () => {
      try {
        const response = await fetch(`${backend_url}/link/linklist/view/${linklistid}`, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const content = await response.json();
        setCurrentLinkListData(content);
        console.log(content," here is LinkList data of user Linklist 11111111111111111111111111111111111111111111");
        console.log(`${backend_url}/profile/get/${linklistid}`);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
      
    })();

    if(isLinkList===false && currentLinkList!==null){
      setIsLinkList(true);
    }
    
  }

  
  return (
    <div className="sharepage">
      
        <Box>
            <div className="sharepage-top">

                
                <div className="sharepage-top-userDetails">
                  
                  <div className="sharepage-top-userimagediv">
                      {userImage===null?( <Skeleton variant="circular" width={97.6} height={96} />):(<img className="sharepage-top-userimage" src={userImage} />)}
                  </div>
                  
                  
                  <div className="sharepage-top-username">
                  <Stack spacing={0}>
                    <div>
                      {userName===null?(<Skeleton variant="text" width={200} height={40} sx={{ fontSize: '1rem' }} />):(<h5 className="sharepage-top-username-h">{userName}</h5>)}
                    </div>

                    <div>
                      {userDescription===null?(<Skeleton variant="text" width={200} height={30} sx={{ fontSize: '1rem' }} />):(<p className="sharepage-top-username-p" >{userDescription}</p>)}
                    </div>
                  </Stack>   
                  </div>

                  
                </div>
                
                <div className="sharepage-top-famousSocialMedia">
                        <div>
                            <IconButton
                            size="large"
                            aria-label="show more"
                            //   aria-controls={}
                            aria-haspopup="true"
                            onClick={()=>{}}
                            color="inherit"
                            >
                            <img className="famoussocialmediaicon" src="https://img.freepik.com/free-vector/instagram-vector-social-media-icon-7-june-2021-bangkok-thailand_53876-136728.jpg?size=338&ext=jpg&ga=GA1.1.1700460183.1713052800&semt=ais"/>
                            </IconButton>            
                        </div>
                        <div>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            //   aria-controls={}
                            aria-haspopup="true"
                            onClick={()=>{}}
                            color="inherit"
                            >
                            <img className="famoussocialmediaicon" src="https://static.vecteezy.com/system/resources/previews/023/986/613/non_2x/facebook-logo-facebook-logo-transparent-facebook-icon-transparent-free-free-png.png"/>
                            </IconButton>
                            </div>
                        <div>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            //   aria-controls={}
                            aria-haspopup="true"
                            onClick={()=>{}}
                            color="inherit"
                            >
                            <img className="famoussocialmediaicon" src="https://www.iconpacks.net/icons/2/free-twitter-logo-icon-2429-thumb.png"/>
                            </IconButton>
                            </div>
                        
                        <div>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            //   aria-controls={}
                            aria-haspopup="true"
                            onClick={()=>{}}
                            color="inherit"
                            >
                            <img className="famoussocialmediaicon" src="https://img.freepik.com/free-psd/social-media-logo-design_23-2151296989.jpg?t=st=1713095544~exp=1713099144~hmac=acdd739054f7f3b3154ab7ff0eba58f01a1d17e443eaeb5ad54024204cdfce3e&w=740"/>
                            </IconButton>
                            </div>
                        <div>

                        
                          <IconButton
                              size="large"
                              aria-label="show more"
                              //   aria-controls={}
                              aria-haspopup="true"
                              onClick={()=>{}}
                              color="inherit"
                              >
                              <img className="famoussocialmediaicon" src="https://static.vecteezy.com/system/resources/previews/023/986/613/non_2x/facebook-logo-facebook-logo-transparent-facebook-icon-transparent-free-free-png.png"/>
                              </IconButton>
                              </div>

                              <div>
                              <IconButton
                              className="box glowing"
                              title="Buy me a cake"
                              size="small"
                              aria-haspopup="true"
                              onClick={()=>{setIsSearchResult(false);}}
                              color="inherit"
                            >
                            <img src={cake} style={{"height":"35px","width":"35px"}}/>
                            </IconButton>
                            </div>
                  
                

                
                
                  

                </div>  

                <div className="sharepage-link-linklist">
                    {isSearchResult==true?(
                     <IconButton
                     size="large"
                     aria-label="show more"
                     //   aria-controls={}
                     aria-haspopup="true"
                     onClick={()=>{setIsSearchResult(false);}}
                     color="inherit"
                     >
                    <ArrowBackIcon />
                    </IconButton>
                    ):(<></>)}
                    {isLinkList==true ?(
                     <IconButton
                     size="large"
                     aria-label="show more"
                     //   aria-controls={}
                     aria-haspopup="true"
                     onClick={()=>{
                      setIsLinkList(false);
                      setCurrentLinkListData(null);
                    }}
                     color="inherit"
                     >
                    <ArrowBackIcon />
                    </IconButton>
                    ):(<></>)}
                    {/* {`${isLinkList}`} */}
                    <div>
                    <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="link" label="Link" />
                        <Tab value="linklist" label="LinkList" />
                        {isLinkList===false && <IconButton
                        size="large"
                        color="inherit"
                        >
                        <SearchIcon onClick={()=>{handleSearchIconClick()}}/>
                        </IconButton>}
                        
                    </Tabs>
                    
                    </Box>
                </div>
                <div>
                  
                {isSearch===true?(
                        <TextField
                        id="searchField" 
                        placeholder="search"
                        value={searchValue}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.keyCode === 13) {
                              e.preventDefault(); // Prevents form submission
                              if(searchValue===''){
                                setIsSearchResult(true);
                                setIsSearch(false);
                              } else{
                                console.log("Search Value:", searchValue);
                                handleSearch();
                                setIsSearchResult(true);
                                setIsSearch(false);
                                
                              }
                              
                              // Add any other logic you want to execute on submit
                          }
                      }}
                          onChange={(e) => { 
                            console.log("Input value:", e.target.value);
                            setSearchValue(e.target.value);}}
                            inputRef={(input) => { searchFieldRef.current = input; }} // Assigning ref using a callback
                        onBlur={()=>{
                          setIsSearch(false);
                          setSearchValue('');
                        }}
                        style={{ display: isSearch ? 'block' : 'none' }}
                        />):("")
                          }
                </div>
                </div>
                <Box sx={{ width: '100%' }}>
                  
                  <div className="sharepage-contentlist">


                  
                  {isSearchResult===false?
                  (
                    value === 'link'?
                    (
                      userLinkData===null?
                      (
                      <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={450} height={60} className="skeletonlink" />
                        <Skeleton variant="rectangular" width={450} height={60} className="skeletonlink" />
                        <Skeleton variant="rectangular" width={450} height={60} className="skeletonlink" />
                        <Skeleton variant="rectangular" width={450} height={60} className="skeletonlink" />
                      </Stack>
                      ):
                      (
                            <div className="sharepage-contentlist-inner-low">
                             
                            {userLinkData.map((item, index) => ( // Add index parameter
                              <div  className="sharepage-contentlist-items">
                               
                                <SharePageListComponent key={index} data={item} buttonText={"open"} linklistClicked={linklistclicked} linkClicked={linkclicked} />
                              </div>
                            
                            ))
                          }
                          </div>
                      )
                      
                    ):(
                      isLinkList===false?(
                        userLinkListData===null?
                      (
                              <Stack spacing={1}>
                              <Skeleton variant="rectangular" width={450} height={60} className="skeletonlink" />
                              <Skeleton variant="rectangular" width={450} height={60} className="skeletonlink" />
                              <Skeleton variant="rectangular" width={450} height={60} className="skeletonlink" />
                              <Skeleton variant="rectangular" width={450} height={60} className="skeletonlink" />
                              </Stack>
                      ): 
                      (
                              <div className="sharepage-contentlist-inner-low">
                              {userLinkListData.map((item, index) => ( // Add index parameter
                                <div  className="sharepage-contentlist-items" >
                                  <SharePageListComponent key={index} data={item} buttonText={"view"} linklistClicked={linklistclicked} linkClicked={linkclicked} />
                                </div>
                              
                              ))
                              }
                              </div>
                      )
                      ):(
                        currentLinkListData===null?
                        (   <Stack spacing={2} direction="row">
                            <CircularProgress variant="determinate" value={progress} />
                            </Stack>
                      
                        ):(

                          currentLinkListData===null?(<>ddd</>):(<LinkList data={currentLinkListData} />)
                        )
                      
                      
                      )

                    )
                  ):
                  (

                      <div className="sharepage-contentlist-inner-low">
                        
                          {
                          value === 'link'?(
                          searchResult.map((item, index) => ( // Add index parameter
                            <div  className="sharepage-contentlist-items">
                            
                              <SharePageListComponent key={index} data={item} linklistClicked={linklistclicked} linkClicked={linkclicked} buttonText={"open"}/>
                            </div>
                          
                          ))
                          ):(
                            searchResult.map((item, index) => ( // Add index parameter
                            <div  className="sharepage-contentlist-items">
                            
                              <SharePageListComponent key={index} data={item} linklistClicked={linklistclicked} linkClicked={linkclicked} buttonText={"view"}/>
                            </div>
                          
                          ))
                          )


                        }
                        </div>

                  )
                }
                


                  {/* <div className="sharepage-contentlist-items"><SharePageListComponent/></div>
                  <div className="sharepage-contentlist-items"><SharePageListComponent/></div>
                  <div className="sharepage-contentlist-items"><SharePageListComponent/></div> */}
                  
                      
                  </div>
                </Box>
            </div>
        </Box>
        {/* <Box sx={{ width: 500 }}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        </Box> */}
        
    </div>
  );
};

export default SharePage;
