import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// 35 charachter for title
// 44 character for description
function SharePageListComponent({data,buttonText,linkClicked,linklistClicked}) {



    return (
    <div className="SharePageListComponent">
        <div className="SharePageListComponentfirstdiv">
            <div className="SharePageListComponentimage">
                <img className="SharePageListComponentimagefile" src="https://cdn.oaistatic.com/_next/static/media/favicon-32x32.be48395e.png" alt="sharepageimage" />
            </div>
        </div>
        <div className="SharePageListComponentseconddiv">
        {buttonText==="view"?
        (
        <div className="SharePageListComponentinfo">
            <div className="SharePageListComponentinfo-p-div" ><h2 className="SharePageListComponentinfo-h" >{data.name}</h2></div>
            
            <p className="SharePageListComponentinfo-p" >{data.description}</p>
        </div>
        ):(
            <div className="SharePageListComponentinfo">
            <div className="SharePageListComponentinfo-p-div" ><h2 className="SharePageListComponentinfo-h" >{data.title}</h2></div>
            
            <p className="SharePageListComponentinfo-p" >{data.description}</p>
        </div>
        )
        }
        </div>
        <div className="SharePageListComponentthirddiv">
            <div className="SharePageListComponentbuttondiv">
                {buttonText==="view"?(<Button onClick={()=>{linklistClicked(data.link_group_id)}} className="SharePageListComponentbutton" 
                // href={data.image}
                variant="contained" color="primary" size="small" 
                // target="_blank" 
                >
                    {buttonText}
                </Button>):(<Button onClick={()=>{linkClicked()}} className="SharePageListComponentbutton" href={data.url} variant="contained" color="primary" size="small" target="_blank" >
                    {buttonText}
                </Button>)}
                
            </div>
        </div>
        
    </div>
  );
}

export default SharePageListComponent;
