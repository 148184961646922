import React,{useState, useEffect} from 'react';
import HomePageHero from '../components/HomePageHero';
import Button from '@mui/material/Button';
import Phone from '../static/images/PhoneScreen.jpg';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SharePageListComponent from '../components/Sharepagelistcomponent';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import cake from '../static/images/piececake.png';
import ShareIcon from '@mui/icons-material/Share';
import DownloadIcon from '@mui/icons-material/Download';    
 

const LinkList = ({data}) => {
  const [isLinkList, setIsLinkList] = useState(false);
  const [progress, setProgress] = useState(0);
  const [value, setValue] = React.useState('link');
  const [isSearch, setIsSearch] = useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [linkData, setlink] = useState(null);
  const [searchValue, setSearchValue] = React.useState('')

  function linkclicked(){
    // alert("linkclicked")
  }

  function linklistclicked(){
    // alert("linklistclicked")
    if(isLinkList===false){
      setIsLinkList(true);
    } 
  }
  useEffect(()=>{
    setlink(data.links);
  },[data])
  return (
    <div className="linklistpage" style={{"display":"flex","flex-direction":"column","gap":"2rem",}}>
    {/* <Box sx={{ width: 500 }}>
    <Skeleton />
    <Skeleton animation="wave" />
    <Skeleton animation={false} />
    </Box> */}
        
        <div className="linklisttop" style={{"display":"flex","gap":"1rem","justify-content":"center","align-items":"center","margin":"0rem 0rem 0rem 0rem"}}>
            
            <div className="linklisttopposterimage">
                <img src={data.image} style={{"height":"8rem","width":"8rem","border-radius":"0.25rem"}}  />
            </div>

            <div className="linklisttopdetails">
                <h3 className="linklisttopdetailstitle" >{data.name}</h3>
                <p className="linklisttopdetailsdescription"  >{data.description}</p>
            </div>

            <div>
                <IconButton>
                    <ShareIcon/>
                </IconButton>
                <IconButton>
                    <DownloadIcon/>
                </IconButton>
            </div>

        </div>

        <div className="linklistMain">
            {linkData===null?(<>d</>):(
              <div className="sharepage-contentlist-inner-low">
                          {linkData.map((item, index) => ( // Add index parameter
                            
                            <div  className="sharepage-contentlist-items">
                              <SharePageListComponent key={index} data={item} linklistClicked={linklistclicked} linkClicked={linkclicked} buttonText={"open"}/>
                            </div>
                          
                          ))
                        }
            </div>)
            }
        </div>

        <div className="linklistbottom">

        </div>
        
    </div>
  );
};

export default LinkList;
